<script setup>
import { ref, watch } from "vue";

import { BModal } from "../bv";
import BodyActions from "./body-actions";

const props = defineProps({
  centered: {
    default: true,
    type: Boolean,
  },
  showActionDivider: {
    default: false,
    type: Boolean,
  },
  timer: {
    default: null,
    type: Object,
  },
});
defineExpose({ cancel, save, show });

const isShow = ref(false);
const resolveToken = ref();

watch(
  () => props.timer && props.timer.active.value,
  (active) => {
    if (!active && isShow.value) {
      save();
    }
  },
);

function show() {
  props.timer && props.timer.start();
  isShow.value = true;
  return new Promise(resolve => (resolveToken.value = resolve));
}

function cancel() {
  close(false);
}

function save(payload) {
  close(payload || true);
}

function close(result) {
  isShow.value = false;
  props.timer && props.timer.stop();
  resolveToken.value(result);
}
</script>

<template>
  <BModal
    v-model="isShow"
    v-bind="$attrs"
    :centered="centered"
    content-class="rounded"
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    data-test="modal-base"
  >
    <BodyActions :show-divider="showActionDivider">
      <!--
        Eventos emitidos dentro de um slot não são capturados pelo componente que envolve
        o slot. Daí as props "save" e "cancel" do tipo Function são passadas abaixo.

        Isso permite que outro componente que estenda o slot possa comandar o fechamento
        deste ModalBase.

        Para isso o componente consumidor deve chamar uma das funções passando opcionalmente
        algum payload para "save".

        Save retornará true caso não haja payload e cancel sempre retorna "false". Quando
        isso acontecer o ModalBase irá se fechar e retornar o valor.
      -->
      <template #body>
        <slot
          name="body"
          :save="save"
          :cancel="cancel"
        />
      </template>

      <template #actions>
        <slot
          name="actions"
          :save="save"
          :cancel="cancel"
        />
      </template>
    </BodyActions>
  </BModal>
</template>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("MessageBase", {
    ref: "modal",
    attrs: {
      variant: "warning",
      title: "ALERTA",
      message: _vm.message,
      "message-detail": _vm.messageDetail,
      countdown: _vm.countdown,
      icon: "alert",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ save, cancel }) {
          return [
            _vm.enableCancel
              ? _c("ButtonSecondary", {
                  attrs: { field: "alertGiveUp", label: _vm.cancelLabel },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("ButtonPrimary", {
              attrs: { field: "alertConfirm", label: _vm.confirmLabel },
              on: {
                click: function ($event) {
                  return save()
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <MessageBase
    ref="modal"
    variant="warning"
    title="ALERTA"
    :message="message"
    :message-detail="messageDetail"
    :countdown="countdown"
    icon="alert"
  >
    <template #default="{ save, cancel }">
      <ButtonSecondary
        v-if="enableCancel"
        field="alertGiveUp"
        :label="cancelLabel"
        @click="cancel()"
      />
      <ButtonPrimary
        field="alertConfirm"
        :label="confirmLabel"
        @click="save()"
      />
    </template>
  </MessageBase>
</template>

<script>
import ButtonPrimary from "../buttons/button-primary";
import ButtonSecondary from "../buttons/button-secondary";
import MessageBase from "./message-base";

export default {
  components: { ButtonPrimary, ButtonSecondary, MessageBase },
  methods: {
    async confirm() {
      const result = await this.$refs.modal.show();
      return result;
    },
  },
  name: "MessageAlert",
  props: {
    cancelLabel: {
      default: "Desistir",
      type: String,
    },
    confirmLabel: {
      default: "Confirmar",
      type: String,
    },
    countdown: {
      default: null,
      type: Number,
    },
    enableCancel: {
      default: false,
      type: Boolean,
    },
    message: {
      default: "Confirma?",
      type: String,
    },
    messageDetail: {
      default: null,
      type: [Array, String],
    },
  },
};
</script>

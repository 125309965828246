<template>
  <MessageBase
    ref="modal"
    variant="danger"
    title="ERRO"
    :message="message"
    :message-detail="messageDetail"
    :countdown="countdown"
    icon="error"
  >
    <template #default="{ cancel }">
      <ButtonPrimary
        label="OK"
        @click="cancel()"
      />
    </template>
  </MessageBase>
</template>

<script>
import { ButtonPrimary } from "../buttons";
import MessageBase from "./message-base";

export default {
  components: { ButtonPrimary, MessageBase },
  methods: {
    show() {
      return this.$refs.modal.show();
    },
  },
  name: "MessageError",
  props: {
    countdown: {
      default: null,
      type: Number,
    },
    message: {
      default: "Ocorreu um erro",
      type: String,
    },
    messageDetail: {
      default: null,
      type: [Array, String],
    },
  },
};
</script>

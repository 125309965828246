import { ref } from "vue";

export function useTimer(seconds) {
  const time = ref();
  const active = ref(false);
  const second = 1000;
  let timeoutId = null;

  const tick = () => {
    time.value--;
    if (time.value > 0) {
      timeoutId = setTimeout(tick, second);
    }
    else {
      time.value = 0;
      active.value = false;
    }
  };

  const start = () => {
    time.value = seconds;
    active.value = true;
    timeoutId = setTimeout(tick, second);
  };

  const stop = () => {
    clearTimeout(timeoutId);
    active.value = false;
  };

  return { active, start, stop, time };
}

<template>
  <span />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["expiration", "perfil"]),
  },
  data() {
    return {
      timeoutId: null,
    };
  },
  methods: {
    ...mapActions("auth", ["signOut"]),
    async alertAndSignOut() {
      await this.$showAlert("O tempo da sua sessão expirou. Você será redirecionado à tela inicial em {countdown} segundo(s).", { countdown: 10 });
      this.signOut();
    },
    calcTimeout(expiration) {
      const timeout = expiration.getTime() - Date.now();
      return timeout;
    },
    pushRouteForAuthState() {
      this.$router
        .push("/default")
        .catch(() => {
          // essa exceção é esperada dado que em alguns casos a navegação é dispara mais de uma vez. o catch é necessário para evitar que o erro se progrague
          return;
        });
    },
    refreshSessionTimer() {
      clearTimeout(this.timeoutId);

      if (!this.expiration) return;

      const timeout = this.calcTimeout(this.expiration);
      this.timeoutId = setTimeout(this.alertAndSignOut, timeout);
    },
  },
  name: "ManagerSession",
  watch: {
    expiration: "refreshSessionTimer",
    perfil: "pushRouteForAuthState",
  },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OverlayBase",
    { attrs: { "is-busy": _vm.isBusy } },
    [
      _c("DialogForm", {
        ref: "form",
        attrs: { "external-errors": _vm.normalizeError(_vm.lastError) },
        on: {
          save: function ($event) {
            return _vm.execute(_vm.authenticate)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                !_vm.lastError
                  ? _c("DescriptionText", { staticClass: "centered" }, [
                      _vm._v(
                        "\n        Por favor, aguarde enquanto tentamos te autenticar.\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ModalBase", {
    ref: "modal",
    attrs: { "show-action-divider": "", size: "lg" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "OverlayBase",
              { attrs: { "is-busy": _vm.isLoadingSeloBackground } },
              [
                _c(
                  "RowForm",
                  [
                    _c(
                      "ColBase",
                      [
                        _c("ControlSelectSingle", {
                          attrs: {
                            field: "assinante",
                            options: _vm.assinantesAsOptions,
                            "value-field": "codigo",
                            "text-field": "label",
                            label: "Assinante",
                            "helper-text":
                              "Escolha aqui o assinante e depois clique na página abaixo para posicionar o selo de assinatura",
                          },
                          model: {
                            value: _vm.currentAssinanteCodigo,
                            callback: function ($$v) {
                              _vm.currentAssinanteCodigo = $$v
                            },
                            expression: "currentAssinanteCodigo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.documento
                  ? _c("PdfDocument", {
                      attrs: {
                        "file-path": _vm.documento.path,
                        "file-name": _vm.documento.nome,
                        overlays: _vm.overlays,
                        "download-blob": _vm.downloadBlob,
                      },
                      on: { documentClick: _vm.handleDocumentClick },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function ({ save: back }) {
          return [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                  width: "100%",
                },
              },
              [
                _c("div", { staticClass: "feedback-message" }, [
                  _vm._v("\n        " + _vm._s(_vm.selosFeedback) + "\n      "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "action-buttons" },
                  [
                    _c("ButtonSecondary", {
                      attrs: {
                        field: _vm.camelCase(_vm.field, "clear"),
                        label: "Limpar posicionamentos",
                      },
                      on: { click: _vm.resetSelos },
                    }),
                    _vm._v(" "),
                    _c("ButtonPrimary", {
                      attrs: {
                        field: _vm.camelCase(_vm.field, "back"),
                        label: _vm.primaryButtonText,
                      },
                      on: {
                        click: function ($event) {
                          return back()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
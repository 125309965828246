<template>
  <OverlayBase :is-busy="isBusy">
    <DialogForm
      ref="form"
      :external-errors="normalizeError(lastError)"
      @save="execute(authenticate)"
    >
      <template #body>
        <DescriptionText
          v-if="!lastError"
          class="centered"
        >
          Por favor, aguarde enquanto tentamos te autenticar.
        </DescriptionText>
      </template>
    </DialogForm>
  </OverlayBase>
</template>

<script>
import { AppError, DescriptionText, DialogForm, OverlayBase, useExecute } from "@/lib";
import { mapActions, mapMutations } from "vuex";

export default {
  components: { DescriptionText, DialogForm, OverlayBase },
  data() {
    const { execute, isBusy, lastError } = useExecute();
    return { execute, isBusy, lastError };
  },
  methods: {
    ...mapActions("auth", ["authenticateWithLink"]),
    ...mapMutations("auth", ["resetState", "setWebAuthnRegistrationDone"]),
    async authenticate() {
      await this.authenticateWithLink();
      // a interface de autenticação via link arbitrariamente dispensa o registro de dispositivo webAuthn. a experiência desejada é iniciar diretamente na tela de assinatura
      this.setWebAuthnRegistrationDone();
    },
    cancel() {
      this.resetState();
      this.$router.push("/default");
    },
    normalizeError(error) {
      const normalError = error ?? new AppError("Algo deu errado com sua autenticação via link.");
      // o requisito explicitamente orientou que não fosse usada a forma padrão da aplicação de recorrer à telas modais de erro. aqui é forçado o campo "form" para que o diálogo exiba a mensagem em texto detro do diálogo.
      if (!normalError.meta) normalError.meta = {};
      normalError.meta = { ...normalError.meta, property: "form" };
      return normalError;
    },
  },
  mounted() {
    this.$refs.form.onSubmit();
  },
  name: "LinkDialog",
};
</script>

<style scoped>
.centered {
  text-align: center;
}
</style>
